.tag-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: -7px !important;
  padding-bottom: -7px !important;
  border-radius: 35px;
}

.tag-wrapper:hover {
  cursor: pointer;
  transition: 600ms;
}

.check-tag-img {
  transition: 400ms;
}

.bottom-total-cost-container p {
  font-weight: bold;
}

.ingredients-table-container {
  width: 100%;
}

.ingredients-table-container th {
  text-align: start;
}
.ingredients-table-container td {
}

.ingredients-table-container td p {
  white-space: nowrap;
  margin-top: 25px;
  align-self: center;
}

.ingredient-th{
  width: 20vw;
}

.sauce-th{
  white-space: nowrap;
}